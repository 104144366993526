import axios from 'axios';
import { URL_LOG } from 'utils/_.env.js';
import SHA1 from 'utils/sha1';
import { c_query } from 'utils/console';
import { PCEK } from 'utils/_.env.js';

const singleton = 'SIN_LOG';
const singletonEnforcer = 'ENFORCER_LOG';

class ApiLog {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this.currentRevision = null;
    this.session = axios.create({
      baseURL: URL_LOG,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
    });
  }

  buildPCEK = (url, data) => {
    let str = JSON.stringify(data) + PCEK;
    //c_query('PCEK:', {url, str, sha1: SHA1(str) });
    //return { headers: { PCEK: SHA1(str) } };
    c_query('PCEK:', {url, data: JSON.stringify(data), PCEK: "opensesame" });
    return { headers: { PCEK: "opensesame" } };
  }

  
  post = (...params) => this.session.post(...params, this.buildPCEK(...params));

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApiLog(singletonEnforcer);
    }

    return this[singleton];
  }
}

export default ApiLog.instance;

