import { ENV, CRYPT_KEY } from "utils/_.env";
import CryptoJS from 'crypto-js';

const PREFIX = 'ENC::';

class CryptoService {
  constructor() {}

  static encrypt(data) {
    data = JSON.stringify(data);
    // if (ENV != "production") {
    //   data = CryptoJS.AES.encrypt(data, CRYPT_KEY).toString();
    //   data = PREFIX + data;
    // }
    return data;
  }

  static decrypt(data) {
    if (data && ENV == "production" && (data.startsWith(PREFIX) || data.startsWith("ENC%2"))) {
      data = data.replace(PREFIX, "");
      data = data.replace("ENC%2", "");
      let bytes = CryptoJS.AES.decrypt(data, CRYPT_KEY);
      data = bytes.toString(CryptoJS.enc.Utf8);
      data = data.length > 0 ? data : "{}";
    }
    
    try {
      return data ? JSON.parse(data) : null;
    } catch (error) {
      return data ? data : null;      
    }
  }
}

export default CryptoService;
