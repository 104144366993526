
export const defaultTheme = {
  col1: {
    primary: '#38E7ED', lprimary: '#7DF7FB', primary2: '#AFF9F7',
    primary3: '#CDF7F4', primary4: '#D9F9F9', primary5: '#F0FFFF'
  },
  col2: {
    accent: '#ED4ADD', laccent: '#F683EA', accent2: '#F4A2F0',
    accent3: '#F0B6F2', accent4: '#F2CEF4', accent5: '#F9E4F8'
  },
  col3: {
    secondary: '#8070E8', lsecondary: '#807CE8', secondary2: '#A3A3EF',
    secondary3: '#B6B6F2', secondary4: '#D0D0F4', secondary5: '#E5E5F9'
  },
  col4: {
    warning: '#E8ED27', lwarning: '#F4F84F', warning2: '#F9F97D',
    warning3: '#F4F0A2', warning4: '#F4F3C1', warning5: '#F9F9D7'
  },
  col5: {
    success: '#90F7CE', lsuccess: '#ABF5DB', success2: '#BCF7E1',
    success3: '#CBF7E6', success4: '#DCF9EC', success5: '#EFF9F5'
  }
}

export const UpdateTheme = (theme) => {
  for (const key in theme) {
    document.documentElement.style.setProperty(`--${key}`, theme[key]);
  }
  return theme;
}

export const resetTheme = () => {
  for (const col_key in defaultTheme) {
    let colors = defaultTheme[col_key];
    for (const key in colors) {
      let value = colors[key];
      document.documentElement.style.setProperty(`--${key}`, value);
    }
  }
}

