export class GA4 {
  static PAGE = {
    STAT: "Estadisticas",
    BOOK: "Libro",
    TEST: "Test",
    TOP_MENU: "top-navbar",
    SIDE_MENU: "side-navbar"
  }

  static SUB_PAGE = {
    CREATE_TEST: "Crear Test",
    DO_TEST: "Hacer Test",
    SUMMARY_TEST: "Resultado de test",
  }
  
  static CLICK = "click";

  static event = (eventName, eventData) => {
    try {
      gtag('event', eventName, eventData);
    } catch (error) {
      console.log('err analytics');
    }
  }
}
