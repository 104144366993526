import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { test } from './../../urls';
import { getGlobalDataForApi } from "./../utils";

export const createFolder = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.createFolder, data),
    (resp) => success(resp),
    (_error) => error(_error),
    { api: test.createFolder, data }
  );
}

export const updateFolder = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.updateFolder, data),
    (resp) => success(resp),
    (_error) => error(_error),
    { api: test.updateFolder, data }
  );
}

export const deleteFolder = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.deleteFolder, data),
    (resp) => success(resp),
    (_error) => error(_error),
    { api: test.deleteFolder, data }
  );
}

export const createTemplate = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.createTemplate, data),
    (resp) => success(resp),
    (_error) => {
      if(error) error(_error);
    },
    { api: test.createTemplate, data }
  );
}

export const deleteTemplate = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.deleteTemplate, data),
    (resp) => success(resp),
    (_error) => { if(error) error(_error) },
    { api: test.deleteTemplate, data }
  );
}

export const deleteTest = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.deleteTest, data),
    (resp) => success(resp),
    (_error) => { if(error) error(_error) },
    { api: test.deleteTest, data }
  );
}

export const updateTest = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.updateTest, data),
    (resp) => success(resp),
    (_error) => error(_error),
    { api: test.updateTest, data }
  );
}

export const testSetFolder = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.testSetFolder, data),
    (resp) => success(resp),
    (_error) => { if(error) error(_error) },
    { api: test.testSetFolder, data}
  );
}

export const createTest = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.createTest, data),
    (resp) => success(resp.createTest),
    (_error) => { if(error) error(_error) },
    { api: test.createTest, data}
  );
}

export const setAnswer = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.setAnswer, data),
    (resp) => success(resp.setAnswer),
    (_error) => { if(error) error(_error) },
    { api: test.setAnswer, data}
  );
}

export const setEvaluatedAnswer = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.setEvaluatedAnswer, data),
    (resp) => success(resp.setEvaluatedAnswer),
    (_error) => { if(error) error(_error) },
    { api: test.setEvaluatedAnswer, data}
  );
}

export const setTimeSpent = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.setTimeSpent, data),
    (resp) => success(resp.setTimeSpent),
    (_error) => { if(error) error(_error) },
    { api: test.setTimeSpent, data}
  );
}

export const repeatTest = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.repeatTest, data),
    (resp) => success(resp.repeatTest),
    (_error) => { if(error) error(_error) },
    { api: test.repeatTest, data}
  );
}

export const continueTest = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.continueTest, data),
    (resp) => success(resp.continueTest),
    (_error) => { if(error) error(_error) },
    { api: test.continueTest, data}
  );
}

export const testEnd = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.testEnd, data),
    (resp) => success(resp.testEnd),
    (_error) => { if(error) error(_error) },
    { api: test.testEnd, data}
  );
}

