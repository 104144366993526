export const account = {
    login: 'account/login',
    logout: 'account/logout',
    forgotPassword: 'account/passwordForgot',
    checkPrivilege: 'account/checkPrivilege',
    getPrivileges: 'account/getPrivileges',
    userSetAttributes: 'account/usrSetAttributes',
    signUp: 'account/signUp',
    signUpCheck: 'account/signUpCheck',
    checkFlashLink: 'account/checkFlashLink',
}

export const bsi = {
    log_event: 'hermes/log_event.php',
}

export const test = {
    createFolder: "test/createFolder",
    getFolderList: 'test/getFolderList',
    updateFolder: 'test/updateFolder',
    deleteFolder: '/test/deleteFolder',
    getTemplateList: '/test/getTemplateList',
    createTemplate: 'test/createTemplate',
    getTemplateNodes: 'test/getTemplateNodes',
    deleteTemplate: 'test/deleteTemplate',
    readTemplate: 'test/readTemplate',
    getRecentTests: 'test/getRecentTests',
    testSetFolder: 'test/testSetFolder',
    updateTest: 'test/updateTest',
    getTestList: 'test/getTestList',
    deleteTest: 'test/deleteTest',
    createTest: 'test/createTest',
    setAnswer: 'test/setAnswer',
    setEvaluatedAnswer: 'test/setEvaluatedAnswer',
    getExtQuestion: 'test/getExtQuestion',
    setTimeSpent: 'test/setTimeSpent',
    repeatTest: 'test/repeatTest',
    continueTest: 'test/continueTest',
    testEnd: 'test/testEnd',
    getTestResult: 'test/getTestResult',
}

export const user = {
    getCustomAttributes: 'user/getCustomAttributes',
    getEnrollments: 'user/getEnrollments',
    setCustomAttributes: 'user/setCustomAttributes',
    getUserSubscription: 'user/getUserSubscription',
}

export const enroll = {
    getTree: 'enroll/getTree',
    setCustomTree: 'enroll/setCustomTree',
    getCustomTree: 'enroll/getCustomTree',
    setStudyGoal: 'enroll/setStudyGoal',
    getStudyGoal: 'enroll/getStudyGoal',
}

export const stats = {
    getTreeStats: 'stats/getTreeStats',
    getStats: 'stats/getStats',
}

export const rewards = {
    setReward: 'rewards/setReward',
    getRewards: 'rewards/getRewards',
    checkAchievement: 'rewards/checkAchievement',
    getAchievements: 'rewards/getAchievements',
    getRankings: "rankings/getRankings",
}

export const book = {
    getItems: 'book/getItems',
    getParents: 'book/getParents',
}

