import axios from 'axios';
import { BASE_URL } from 'utils/_.env.js';
import SHA1 from 'utils/sha1';
import { c_query } from 'utils/console';
import { PCEK } from 'utils/_.env.js';
import { AuthStorage } from 'storage/Auth.storage';

const singleton = 'SINGLETON';
const singletonEnforcer = 'ENFORCER';

class ApiService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this.currentRevision = null;
    this.session = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
    });
  }

  getPcek = (data, url) => {
    let str = JSON.stringify(data) + PCEK;
    c_query('PCEK:', { url, str, sha1: SHA1(str) });
    return SHA1(str);
  }

  #getAccessToken = () => {
    if (AuthStorage.isLogged()) return AuthStorage.getAccessToken();
    return ""
  }

  buildHeaders = (url, data) => {
    return { headers: { 
      PCEK: this.getPcek(data, url),
      at: this.#getAccessToken(),
    }};
  }

  get = (...params) => this.session.get(...params);
  
  post = (...params) => this.session.post(...params, this.buildHeaders(...params));

  put = (...params) => this.session.put(...params);

  patch = (...params) => this.session.patch(...params);

  delete = (...params) => this.session.delete(...params);

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApiService(singletonEnforcer);
    }

    return this[singleton];
  }
}

export default ApiService.instance;

