import { UserApi } from 'api_connection';

export const ATTR = {
  LAST_PRODUCT: 'last_product',
  ACHIEVEMENT: 'achievement'
}

export class CloudService {

  constructor() {  }

  setData(key, value, callback){
    UserApi.read.getCustomAttributes({  }, (resp) => {
      let data = { ...resp }
      data[key] = value;

      UserApi.write.setCustomAttributes({ catt: JSON.stringify(data) }, () => {
        if(callback) callback();
      })
    });
  }

  getData(key, callback){
    UserApi.read.getCustomAttributes({  }, (resp) => {
      if(callback) callback(resp[key]);
    });
  }

  clearData(callback){
    UserApi.write.setCustomAttributes({ catt: "" }, () => {
      if(callback) callback();
    })
  }

  getAllData(callback){
    UserApi.read.getCustomAttributes({  }, (resp) => {
      if(callback) callback(resp);
    });
  }
}

