import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { user } from './../../urls';
import { getGlobalDataForApi } from "./../utils";

export const setCustomAttributes = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(user.setCustomAttributes, data),
    (resp) => success(resp.setCustomAttributes),
    (_error) => { if(error) error(_error) },
    { api: user.setCustomAttributes, data}
  );
}

