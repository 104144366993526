import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { user } from './../../urls';
import { getGlobalDataForApi } from "./../utils";

export const getCustomAttributes = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(user.getCustomAttributes, data),
    (resp) => {
      success(resp.getCustomAttributes.catt)
    },
    (_error) => { if(error) error(_error) },
    { api: user.getCustomAttributes, data}
  );
}

export const getEnrollments = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(user.getEnrollments, data),
    (resp) => { success(resp.getEnrollments) },
    (_error) => { if(error) error(_error) },
    { api: user.getEnrollments, data}
  );
}

export const getUserSubscription = async (data, success, error) => {
  HandleRequest(
    () => ApiService.post(user.getUserSubscription, data),
    (resp) => { success(resp.getUserSubscription) },
    (_error) => { if (error) error(_error) },
    { api: user.getUserSubscription, data }
  );
}
