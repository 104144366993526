import { render, html } from 'lit-html';
import { toCamelCase } from 'utils/to-camel-case';
import styles from './_.styles.scss';
import achievementsModal from './build';

const URL_STATIC = "https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/"

class AchievementsModal extends HTMLElement {

  /** Definition of component props to be listened when they change */
  static get observedAttributes() {
    return [
      'username',
      'open',
      'achievementImg',
      'ariaLevel',
      'achievementName',
      'ludus',
      'ludins',
      'description',
    ];
  }

  get username() {return this.getAttribute('username') || undefined}
  set username(value) {this.setAttribute('username', value)}
  
  get achievementImg() {return this.getAttribute('achievementImg') || undefined}
  set achievementImg(value) {this.setAttribute('achievementImg', value)}
  
  get ariaLevel() {return this.getAttribute('ariaLevel') || undefined}
  set ariaLevel(value) {this.setAttribute('ariaLevel', value)}
  
  get achievementName() {return this.getAttribute('achievementName') || undefined}
  set achievementName(value) {this.setAttribute('achievementName', value)}
  
  get ludus() {return this.getAttribute('ludus') || undefined}
  set ludus(value) {this.setAttribute('ludus', value)}
  
  get ludins() {return this.getAttribute('ludins') || undefined}
  set ludins(value) {this.setAttribute('ludins', value)}
  
  get description() {return this.getAttribute('description') || undefined}
  set description(value) {this.setAttribute('description', value)}
  
  // get text() {return this.getAttribute('text') || undefined}
  // set text(value) {this.setAttribute('text', value)}

  get open() {return this.getAttribute('open') === 'true'}
  set open(value) {
    const v = value === 'true';
    this.setAttribute('open', v);
  }

  /** Initializer of component definition */
  constructor() {
    super();
    this.__body = document.body;
    this.#__init();
    this.__conffeti = false;
    this.__achievements_queue = [];
  }

  /** Element created and initialized in DOM */
  connectedCallback() {
    this.__initialized = true;
    this.toggleBlockScrollIfNeeded();
    this.#__render();
  }

  /** Trigger change props provided to the component */
  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.__initialized) {
      if (oldValue !== newValue) {
        this[toCamelCase(attributeName)] = newValue
        this.toggleBlockScrollIfNeeded();
        this.#__render();
      }
    }
  }

  toggleBlockScrollIfNeeded() {
    if (this.open) {
      this.__body.classList.add('block-scroll');
      this.classList.add('visible');
      return;
    } 
    this.__body.classList.remove('block-scroll');
    if(this.classList.contains('visible')) this.classList.add('visible-out');
    setTimeout(() => {
      this.classList.remove('visible');
      this.classList.remove('visible-out');
    }, 500);
  }

  close(){
    achievementsModal(false);
  }
  
  showConffeti(){
    if(this.__conffeti) return;
    setTimeout(() => {
      this.__conffeti = true;
      this.#__render();
    }, 1500);
    setTimeout(() => {
      this.__conffeti = false;
      this.#__render();
    }, 4700);
  }

  /** ______________________ RENDER ______________________________ */
  #template() {
    const { username, achievementImg, ariaLevel, achievementName, ludus, ludins, description, __conffeti } = this;
    const achievement_icon = `${URL_STATIC}achievements/icons/${achievementImg.toLowerCase()}.png`;
    const achievement_confeti = `${URL_STATIC}achievements/achievements_particles.gif`;
    return /* template */html`
    <div class="overlay"></div>
    <div class="ml_a-modal">
      <p class="ml_paragraph p_14 medium ">¡Enhorabuena ${username}!</p>
      <p class="ml_paragraph p_12 regular ">Has desbloqueado un nuevo logro</p>
      <div class="achievement-logo">
        ${__conffeti? html`<img src="${achievement_confeti}" class="particles" alt="ludubot">`: ``}
        <div class="achievement-bg ${__conffeti? 'conffeti': ''} level-${ariaLevel}"></div>
        <div class="achievement-bg-front ${__conffeti? 'conffeti': ''}  level-${ariaLevel}"></div>
        <img class="icon ${__conffeti? 'conffeti': ''}" src="${achievement_icon}" alt="ludubot">
      </div>
      <p class="ml_paragraph title_4">${achievementName}</p>
      <p class="ml_paragraph p_14 medium ">Nivel ${ariaLevel}</p>
      <div class="ludus_ludins">
        <p class="ml_paragraph title_4">${ludus}</p>
        <i class="ml-icon ml-icon-ludus ml_primary ml-icon-sm"></i>
        <p class="ml_paragraph title_4">${ludins}</p>
        <i class="ml-icon ml-icon-ludin ml_primary ml-icon-sm ludins"></i>
      </div>
      <p class="ml_paragraph p_14 medium ">${description}</p>
      <i @click=${this.close.bind(this)} class="ml-icon ml-icon-cerrar ml_primary ml-icon-lg close_btn"></i>
    </div>
    `;
  }
  /** ____________________________________________________________ */



  /**
   * ========================= PRIVATE FUNCTIONS ===========================
   * (Don't modify the functions below. Copy-paste to other web components)
   * ==================================================================== */

  /** Init web component config to be executed in constructor */
  #__init({ shadowDOM = true } = {}) {
    this.__isShadowDOM = shadowDOM;
    if (this.__isShadowDOM) this.root = this.attachShadow({ mode: 'open'});
    this.#__render();
    this.#__attachStyle();
    this.__initialized = false;
  }

  /** Inject processed SCSS Style */
  #__attachStyle() {
    const style = document.createElement('style');
    style.textContent = styles;
    const appendStyleTo = this.__isShadowDOM
      ? this.root
      : document.head || document.getElementsByTagName('head')[0];

    appendStyleTo.appendChild(style);
  }


  /** Internal function to update the UI based (re-render and first render) */
  #__render() {
    const attachTo = this.__isShadowDOM ? this.root : this;
    render(this.#template(), attachTo);
  }
  /** ============================================================= */
}

window.customElements.define('ml-achievements-modal', AchievementsModal);

