const SHA1 = (msg) => {
  const rotate_left = (n, s) => {
    let t4 = ( n << s ) | (n >>> (32 - s));
    return t4;
  }

  const lsb_hex = (val) => {
    let str = '', vh, vl;
    for(let i=0; i <= 6; i += 2 ) {
      vh = (val >>> (i * 4 + 4)) & 0x0f;
      vl = (val >>> (i * 4)) & 0x0f;
      str += vh.toString(16) + vl.toString(16);
    }
    return str;
  }
  
  const cvt_hex = (val) => {
    let str = '', v;
    for(let i = 7; i >= 0; i--) {
      v = (val >>> (i * 4)) & 0x0f;
      str += v.toString(16);
    }
    return str;
  };

  const Utf8Encode = (string) => {
    string = string.replace(/\r\n/g,'\n');
    let utf_text = '';
    for (let n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);
      if (c < 128) {
        utf_text += String.fromCharCode(c);
      }
      else if((c > 127) && (c < 2048)) {
        utf_text += String.fromCharCode((c >> 6) | 192);
        utf_text += String.fromCharCode((c & 63) | 128);
      }
      else {
        utf_text += String.fromCharCode((c >> 12) | 224);
        utf_text += String.fromCharCode(((c >> 6) & 63) | 128);
        utf_text += String.fromCharCode((c & 63) | 128);
      }
    }
    return utf_text;
  };

  let i, j;
  let W = new Array(80);
  let H0 = 0x67452301;
  let H1 = 0xEFCDAB89;
  let H2 = 0x98BADCFE;
  let H3 = 0x10325476;
  let H4 = 0xC3D2E1F0;
  let A, B, C, D, E;
  let temp;
  msg = Utf8Encode(msg);
  let msg_len = msg.length;
  let word_array = new Array();

  for(let i=0; i < msg_len-3; i += 4) {
    j = msg.charCodeAt(i) << 24 |
      msg.charCodeAt(i + 1) << 16 |
      msg.charCodeAt(i + 2) << 8 |
      msg.charCodeAt(i+3);
    word_array.push(j);
  }
  
  switch(msg_len % 4) {
    case 0:
      i = 0x080000000;
      break;
    case 1:
      i = msg.charCodeAt(msg_len - 1) << 24 | 0x0800000;
      break;
    case 2:
      i = msg.charCodeAt(msg_len - 2) << 24 |
        msg.charCodeAt(msg_len - 1) << 16 |
        0x08000;
      break;
    case 3:
      i = msg.charCodeAt(msg_len - 3) << 24 |
        msg.charCodeAt(msg_len - 2) << 16 |
        msg.charCodeAt(msg_len - 1) << 8 |
        0x80;
      break;
  }

  word_array.push(i);
  while((word_array.length % 16) != 14 ) word_array.push(0);
  word_array.push(msg_len >>> 29);
  word_array.push((msg_len << 3) & 0x0ffffffff);

  for (let block_start = 0; block_start < word_array.length; block_start += 16) {
    for(let i = 0; i < 16; i++ ) W[i] = word_array[block_start+i];
    for(let i = 16; i <= 79; i++) W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
    A = H0;
    B = H1;
    C = H2;
    D = H3;
    E = H4;
    for(let i = 0; i <= 19; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }

    for(i = 20; i <= 39; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }

    for(let i = 40; i <= 59; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }

    for(let i = 60; i <= 79; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    H0 = (H0 + A) & 0x0ffffffff;
    H1 = (H1 + B) & 0x0ffffffff;
    H2 = (H2 + C) & 0x0ffffffff;
    H3 = (H3 + D) & 0x0ffffffff;
    H4 = (H4 + E) & 0x0ffffffff;
 }
 temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);

 return temp.toLowerCase();
}

export default SHA1;

