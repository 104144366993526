import * as Sentry from "@sentry/browser";
import "regenerator-runtime/runtime.js";
import "bed-path-dot-home/guide_lines_all.js";
import "bed-path-dot-home/components/index.js";
import "./style.scss";
import 'utils/link_creator';
import luduModal from 'components/global/ludu-modal/build';
import { toNode } from 'utils/html_creator';
import { BASE_URL, UNITY_ENV } from 'utils/_.env.js';

import App from './App';
import { router } from 'routing';
import 'components/global/mobile-menu';
import 'components/global/top-menu';
import 'components/global/ludu-modal';
import 'components/global/achievements-modal';

import {loadAchievementsModal} from 'services/AchievementService.js'
import { resetUnityVars, getUnityPixelRatio } from 'utils/operators';
import { MDD, ENV } from 'utils/_.env';
import AuthService from "services/AuthService";
import { captureConsoleIntegration } from "@sentry/integrations";
import { AuthStorage } from "storage/Auth.storage";
import { EnrollStorage } from "./storage/Enroll.storage";

//SENTRY CONFIG

const $sentryTarget = MDD ? "estudioenremoto.com": "campus.meludus.com";
if(ENV == 'production'){
  Sentry.init({
    dsn: "https://738d3704a8864faab58c1f984e471b23@o1184240.ingest.sentry.io/4505442758557696",
    beforeSend: (event, hint) => {
      let authData = localStorage.getItem("AUTH");
      let user = "NoLogged";

      if(authData){
        user = JSON.parse(authData).user;
        if(Object.keys(user).length) user = `${user.pid}||${user.nick}`;
      }
      
      event.extra = { ref: user }

      return event;
    },
    environment: MDD ? "mdd" : "campus",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [$sentryTarget],
        //tracePropagationTargets: ["localhost:3030"],
      }),
      new Sentry.Replay(),
      captureConsoleIntegration({
        levels: ['error']
      })
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
  });
}

resetUnityVars();

document.addEventListener('DOMContentLoaded', App);
window.addEventListener("popstate", router);

// CHECK LOGIN WITH ACCESS TOKEN
const authService = new AuthService();
document.addEventListener("visibilitychange", () => {
  if(document.visibilityState == 'visible'){
    authService.checkAccessTokenAndLogin();
  }
})

const targetNode = document.querySelector("#root");
const observerOptions = { childList: true, attributes: false }

const observer = new MutationObserver((list, observe) => {
  const action = list[0];
  if(action && action.type == 'childList' && action.target.children.length > 0){
    let body = action.target.firstChild;
    if(!body.classList.contains('js_exception')){
      setTimeout(() => {
        body.classList.add('ml_display');
      }, 10);
    }
  }
});

observer.observe(targetNode, observerOptions);


/** ______________________ GLOBAL METHODS ______________________________ */

window.g_loadInstance = (ins) => {
  if(ins != null){
    window.unityInstance = ins;
  }else{
    return window.unityInstance;
  }
}

window.g_loading = (flag, full = false) => {
  g_loadingForSection(flag, full);
}

window.g_loadingForSection = (show = true, fullWidth = false) => {
  const loading = document.querySelector('#loading-for-section');

  if (loading) {
    if (show) {
      if (fullWidth) loading.classList.add('full-width'); 
      loading.classList.add('show');
      setTimeout(() => loading.classList.add('animate'), 100);
    } else {
      loading.classList.remove('animate');
      setTimeout(() => {
        loading.classList.remove('show');
        loading.classList.remove('full-width');
        loading.classList.remove('animate');
      }, 1000);
    }
  }
}

window.g_auth = () => {
  const { ua, user, token } = AuthStorage.getAuth();
  const rewards = EnrollStorage.getRewards();
  const { licenseId } = EnrollStorage.getEnrollSelected();

  return JSON.stringify({
    pa_id: ua?.partner_id,
    pid: user?.pid,
    nick: user?.nick,
    eid: licenseId,
    email: user?.email,
    ua_col: ua?.theme,
    token,
    ludins: rewards?.general?.ludis,
    ludus: rewards?.general?.ludus,
    likes: rewards?.general?.likes,
    achievement: rewards?.general?.achievements
  })
};

window.g_ludubot = (face = "cargando", id = "ludu_cmp", hideBody = true) => {
  const ludu = toNode(`
    <ml-ludubot id="${id}" direction="up-down" target="50,50" ludubot="${face}"></ml-ludubot>
  `)
  if(hideBody){
    const root = document.querySelector('#root').firstElementChild.firstElementChild;
    if (root) root.classList.remove('ml_display');
  }
  let body = document.querySelector('body');
  ludu.updateFace(face);
  body.appendChild(ludu);
}

window.g_showErrorMsg = (title = "", text = "") => {
  try{
      if(title == "") title = "Ups! Ha sucedido un error...";
      if(text == "") text = "Prueba a actualizar la página";

      g_loading(false);
      const ludu = toNode(`
      <ml-ludu-modal heading-title="${title}" open="true" face="error" text="${text}">
      <ml-button
      id="refresh_btn"
      class-name="ml_btn-secondary card-btn"
      text="actualizar página"></ml-button>
      </ml-ludu-modal>`);
      let root = document.querySelector('#root').firstChild,
          body = document.querySelector('body');
      if(root) root.classList.remove('ml_display');
      body.appendChild(ludu);
      ludu.addEventListener('click', () => {
        ludu.open = false;
        window.location.reload();
      });
  } catch (error) {}
}

window.getSysData = () => {

  let env = 'LOC';
  if(!UNITY_ENV && ENV == 'staging') env = 'DEV';
  if(ENV == 'production') env = 'PRO';
  console.log('ENV', env);
  console.log('pixelratio', getUnityPixelRatio());
  return JSON.stringify({
    path: BASE_URL,
    env: env,
    dpr: getUnityPixelRatio()
  })
}

window.loadLuduModal = (flag=true, data) => luduModal(flag, data);

window.setAchievement = (data) => loadAchievementsModal(JSON.parse(data));

window.g_showTitle = (flag=false) => {
  let title = document.querySelector('ml-title');
  if(title && title.fade == (!Boolean(flag)).toString()) return;
  if(title) title.fade = `${!flag}`;
}

window.g_updateAvatar = () => {
  let menu = document.querySelector('ml-menu');
  menu.refreshAvatar();
}

