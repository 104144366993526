
const INITIAL_STATE = {
  isFirstTime: true,
  onBoarding: "",
  achievementQueue: [],
  settings: {
    sidebarOpen: true,
  }
}

const KEY = "SETTING";

export class SettingsStorage {

  // SETTERS:
  static setFirstTime(data) {
    const currentData = this.#get();

    this.#set({ ...currentData, isFirstTime: false });
  }

  static setSidebarOpen(state) {
    const currentData = this.#get();

    this.#set({ 
      ...currentData, 
      settings: { 
        ...currentData.settings, 
        sidebarOpen: state 
      }
    });
  }

  static setOnBoarding(state) {
    const currentData = this.#get();

    this.#set({ 
      ...currentData, 
      onBoarding: state 
    });
  }

  static setAchievementQueue(data) {
    const currentData = this.#get();
    this.#set({ 
      ...currentData, 
      achievementQueue: JSON.stringify(data)
    });
  }


  // GETTERS:
  static getFirstTime() {
    return this.#get().isFirstTime;
  }

  static getSidebarOpen() {
    return this.#get().settings.sidebarOpen;
  }

  static getOnBoarding() {
    return this.#get().onBoarding;
  }

  static getAchievementQueue() {
    return JSON.parse(this.#get().achievementQueue);
  }


  // OTHERS:
  static clear() {
    this.#set(INITIAL_STATE);
  }


  static #set(data) {
    localStorage.setItem(KEY, JSON.stringify(data));
  }

  static #get() {
    return JSON.parse(localStorage.getItem(KEY)) || INITIAL_STATE;
  }
}