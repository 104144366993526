import { CookiesService } from "services/cookies";
import { AuthStorage } from "./Auth.storage";
import { AchievementStorage } from "./Achievement.storage";
import { TreeStorage } from "./Tree.storage";
import { EnrollStorage } from "./Enroll.storage";

export class StorageService {

  static clearAll() {
    AuthStorage.clear();
    AchievementStorage.clear();
    TreeStorage.clear();
    EnrollStorage.clear();
    CookiesService.clearAll();
  }
}