export const treatLuduFace = (value) => {
  const permit = {
    cargando: "cargando",     cargando_impresora: "cargando_impresora",
    enfadado: "enfadado",     error: "error",
    estudiando: "estudiando", festejando: "festejando",
    meditando: "meditando",   pensando_brazo_derecho: "pensando_brazo_derecho",
    reposo: "reposo",         pensando_brazo_izq_: "pensando_brazo_izq_", 
    triste: "triste",
  }
  return permit[value] ? permit[value] : 'reposo';
}

export const getBtnClass = (value) => {
  const permit = {
    primary: "ml_btn-primary",
    secondary: "ml_btn-secondary", 
    terciary: "ml_btn-terciary",
  }
  return permit[value] ? permit[value] : 'ml_btn-primary';
}

