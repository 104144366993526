// THESE ARE PAGES AND IN EACH OF THEM THERE IS THEIR MESSEAGES.

export const apiModalMsg = {
  // Ups, ¡Ha habido un error!
  title: 'Fallo la operación',
  body_title: "Hubo un error al realizar la operación.",
  generic_msg: 'Intente mas tarde por favor.',
}

export const loginMsg = {
  mdd_fail_title: 'Usuario no identificado',
  mdd_fail_text: 'Por favor regresa a tu aula e intenta ingresar nuevamente.',
  valid_name: 'El email, nick o id usuario no es correcto.',
  valid_pass: 'Por favor escribe una contraseña.',
  error_email: 'El email, nick o id usuario no es correcto.',
}

export const notFoundMsg = {
  title: 'No hemos encontrado lo que buscas.. ¿Estás seguro de que la URL es correcta?',
  btn: 'Regresar al inicio',
}

export const forgotPassMsg = {
  valid_email: 'El email, nick o id usuario no es correcto.',
}

export const temporalPassMsg = {
  valid_pass: 'Debes escribir una contraseña.',
}

export const registerMsg = {
  title: '¿ya tienes cuenta?',
  btn: 'entrar al aula',
  main_title: '¡Hola! Regístrate y prueba gratis',
  ch_newsletter: 'Me gustaría recibir información en mi email.',
  ch_legal: `Acepto la <span class="ml_rb_link">política de privacidad</span> y los <span class="ml_rb_link">términos y condiciones.</span>`,
  btn_2: 'ya tengo cuenta',
  error_title: '¡Ups! algo no ha ido bien.',
  error_msg: `No hemos podido registrarte. Por favor vuelve a intentarlo y si el error <br> persiste puedes escribirnos a hola@meludus.com`,
  success_title: '¡Ya casi terminamos!',
  success_msg_1: 'Revisa tu email, te hemos enviado un link <br> para comprobar tu identidad.',
  success_msg_2: 'Si no te llega el email recuerda revisar tu <br> bandeja de spam.',
  valid_name: 'El nombre de usuario no es valido.',
  valid_email: "El email no es valido.", 
  valid_pass: "Contraseña invalida.",
}

export const flashLinkMsg = {
  title: 'Usuario no identificado',
  bad_link: "Por favor, regresa a tu aula e intenta acceder nuevamente.",
}
