import { __addDefaultActivities, formatGetRewards } from "utils/operators";

const INITIAL_STATE = {
  enrollSelected: {
    licenseId: -1, // Enroll ID
    licenseValidTo: null,
    licenseValidFrom: null,
    daysRemaining: null,
    productId: -1,
    name: "",
    activities: []
  },
  enrollList: [],
  rewards: null,
  goalDate: null,
}

const KEY = "ENROLL";

export class EnrollStorage {

  // SETTERS:
  static setLicenseSelected(license) {
    
    this.#set({ 
      ...this.#get(),
      enrollSelected: {
        licenseId: license.lid,
        licenseValidTo: license.lvt,
        licenseValidFrom: license.lvf,
        daysRemaining: license.drem,
        productId: license.prid,
        name: license.nm,
        activities: license.acts.map(activity => {
          return {
            type: activity.atp,
            validTo: activity.vt,
            validToTimestamp: new Date(activity.vt).getTime(),
            validFrom: activity.vf,
            daysRemaining: activity.drem,
          }
        })
      }
    });
  }

  static setLicenses(licenses) {
    this.#set({
      ...this.#get(),
      enrollList: licenses.map(license => {
        license.acts = __addDefaultActivities(license.acts);

        return {
          licenseId: license.lid,
          licenseValidTo: license.lvt,
          licenseValidFrom: license.lvf,
          daysRemaining: license.drem,
          productId: license.prid,
          name: license.nm,
          activities: license.acts.map(activity => {
            return {
              type: activity.atp,
              validTo: activity.vt,
              validToTimestamp: new Date(activity.vt).getTime(),
              validFrom: activity.vf,
              daysRemaining: activity.drem,
            }
          })
        }
      }),
    })
  }

  static selectLicense(productId) {
    const licenses = this.#get().enrollList;
    const license = licenses.find(l => l.productId === productId);

    this.#set({
      ...this.#get(),
      enrollSelected: license
    })

  }

  static setRewards(rewards) {
    this.#set({
      ...this.#get(),
      rewards: formatGetRewards(rewards)
    });
  }

  static setStudyGoal(goalDate) {
    this.#set({
      ...this.#get(),
      goalDate
    });
  }


  // GETTERS:
  static getEnrollList() {
    return this.#get().enrollList;
  }

  static getEnrollSelected() {
    return this.#get().enrollSelected;
  }

  static getRewards() {
    return this.#get().rewards;
  }

  static getStudyGoal() {
    return this.#get().goalDate;
  }



  // OTHERS:
  static clear() {
    this.#set(INITIAL_STATE);
  }


  static #set(data) {
    localStorage.setItem(KEY, JSON.stringify(data));
  }

  static #get() {
    return JSON.parse(localStorage.getItem(KEY)) || INITIAL_STATE;
  }
}