export const item_types = {
  OBJ_TITLE: 250,
  TITLE: 251,
  BLOCK: 252,
  THEME: 253,
  TEXT: 254,
  NUMBER: 255,
  PREFAB_BLOCK: 302,
  PREFAB_LESSON: 303,
  PREFAB_NUMBER: 305,
  PROGRAM_KB: 260, // DETERMINA SI ES LEY O PROGRAMA EN 2DO NIVEL

  // LAW TYPES
  MAIN_TITLE: 1,
  BOOK: 2,
  TITLE_LAW: 3,
  CHAPTER: 4,
  SECTION: 5,
  ARTICLE: 6,
  PARAGRAPH: 7,
  TITLE_NOTE: 8,
  BODY_NOTE: 9,
  ADD_PROVISION: 10,
  TRANSITIONAL_PROVISION: 11,
  REPEAL_PROVISION: 12,
  FINAL_PROVISION: 13,
  SUBSECTION: 14,
  PREAMBLE: 15,
  SIGN: 16,
  BLOCK_LAW: 17,
  ANNEXED: 18,
  TABLE: 19,
  TEMARY: 20,
  THEME_LAW: 21,
  EPIGRAPH: 22,
  SUB_EPIGRAPH: 23,
  PULL_APART: 24,
  SUB_PULL_APART: 25,
  AUTHOR_PARAGRAPH: 26,
  REPEALED: 27,

  // add on front
  PROVISIONS_GROUP: -100
}

export const nav = {
  NONE: false,
  BACK: 'BACK',
  MENU: 'MENU'
}

// Describe behavior of page according this param
export const pageTypes = {
  TEMPLATE: 'template',
  RE_CONFIG: 'config',
  BOOK: 'book',
}

export const activities = {
  TEST: 1,
  PRACTICAL_CASE: 2,
  MEMO: 3,
  LUDU_LIBRO: 4,
  DECONSTRUCTOR: 5,
  AUDIO: 6,
  SCHEME: 7,
  POMODORO: 8,
  STATS: 9
}

export const VALIDATE_QU = {
  ONE_BY_ONE_WITH_EXP: 0,
  ONE_BY_ONE_WITHOUT_EXP: 1,
  AT_THE_END_WITH_EXP: 2,
}

export const $stats = {
  TEST_QT: 1,
  POMODORO_QT: 2,
  TEST_TIME: 10,
  POMODORO_TIME: 20
}

export const treeItem = {
  id: 'ii',
  cnt: 'ic',
  prt: 'ip',
  pos: 'ipo',
  typ: 'it',
  ntyp: 'int',
	stop: 'iv',
	demo: 'id',
	sts: 'is',
	oid: 'io',
	bread: 'ib',
	pxy_rid: 'ix', // proxy root id
	pxy_id: 'xi',
	pxy_nm: 'xn',
	pxy_sts: 'xs',
	pxy_cls: 'xc',
	children: 'h',
  // add on front
  state: 'state', // <- For check, uncheck and partial.
  stat: 'stat', // <- node's statistics.
  level: 'il',
  path: 'ipa',
  prefix: 'ipr',
}
