import { loadData, toNode } from 'utils/html_creator';
import view from './layout.html';
import { SettingsStorage } from 'storage/Settings.storage.js';

const AchievementsModal = (flag=true, data = {}) => {
  let element = document.getElementById("achievementsModal");
  
  if(flag){
    if(!data.userName) return;
    if(!data.achievementImgPath) return;
    if(!data.level) return;
    if(!data.achievementName) return;
    if(!data.ludins) return;
    if(!data.ludus) return;
    if(!data.description) return;
    let achievements_array = [];

    if(SettingsStorage.getAchievementQueue().length)
      achievements_array = SettingsStorage.getAchievementQueue();

    achievements_array.push(data);
    SettingsStorage.setAchievementQueue(achievements_array);
  }

  if (element && flag){ 
    return;
  } 
  
  if(!flag){
    if(element){
      element.open = "false";
      setTimeout(() =>{
        element.remove();
        let achievements_queue = [];
        if (SettingsStorage.getAchievementQueue().length)
          achievements_queue = SettingsStorage.getAchievementQueue();

        achievements_queue.shift();
        SettingsStorage.setAchievementQueue(achievements_queue);
        if(achievements_queue.length > 0){
          newView(achievements_queue[0]);
        }
      }, 1000);
    }
  }else{
    let achievements_array = [];
    achievements_array.push(data);
    SettingsStorage.setAchievementQueue(achievements_array);
    newView(data);
  }
  
}

const newView = (data) =>{
  let $view = toNode(loadData(view, {
    username: data.userName,
    achievementImg: data.achievementImgPath,
    ariaLevel: data.level,
    achievementName: data.achievementName,
    ludus: data.ludins,
    ludins: data.ludus,
    description: data.description
  }));document.querySelector('body').appendChild($view);
  setTimeout(() => {
    $view.open = "true";
    $view.showConffeti();
  }, 500);
}

export default AchievementsModal;
