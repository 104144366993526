export const cards = {
  cards: [
    {
      cid: 1,
      cnm: 'Memo nro 01',
      th: 'Artículo 35. Honorarios de los abogados.',
    },
    {
      cid: 2,
      cnm: 'Mi test real',
      th: 'CAPÍTULO I: De la jurisdicción de los tribunales civiles y las cuestiones prejudiciales',
    },
    {
      cid: 3,
      cnm: 'Prioridad 01',
      th: 'Artículo 38. Apreciación de oficio de la falta de competencia internacional y de jurisdicción.',
    },
    {
      cid: 4,
      cnm: 'Ley importante',
      th: 'Sección 2.ª De las cuestiones prejudiciales',
    },
    {
      cid: 5,
      cnm: 'Memo 02',
      th: 'Sección 1.ª De la competencia objetiva',
    },
    {
      cid: 6,
      cnm: 'Falle 5 veces',
      th: 'Artículo 47. Competencia de los Juzgados de Paz.',
    },
  ]
}

