import CryptoService from 'services/CryptoService';

export const setState = (data = {}, key = 'page-state') => {
  if(typeof data === 'object' && data !== null){
    if(sessionStorage.getItem(key)){
      let _data = sessionStorage.getItem('page-state');
      let state = CryptoService.decrypt(_data);
      data = { ...state, ...data };
    }
    sessionStorage.setItem(key, CryptoService.encrypt(data));
    return true;
  }
  return false;
}

export const getState = (key = 'page-state') => {
  if(sessionStorage.getItem(key)){
    let data = sessionStorage.getItem('page-state');
    return CryptoService.decrypt(data);
  }else{
    return {};
  }
}

export const removeKeyFromState = (_key, key = 'page-state') => {
  if(sessionStorage.getItem(key)){
    let data = sessionStorage.getItem('page-state');
    data = CryptoService.decrypt(data);
    delete data[_key];
    sessionStorage.setItem(key, CryptoService.encrypt(data));
    return true;
  }
  return false;
}
