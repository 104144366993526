import { CookiesService } from 'services/cookies';
import { navigateTo } from 'routing';
import { CloudService, ATTR } from 'services/CloudService';
import { AccountApi, UserApi } from 'api_connection';
import { ENV, MDD } from 'utils/_.env';
import { __addDefaultActivities, __formatLicenses } from 'utils/operators';
import { URLS } from 'routing/urls';
import { StorageService } from 'storage/Storage.storage';
import { AuthStorage } from 'storage/Auth.storage';
import { EnrollStorage } from 'storage/Enroll.storage';

class AuthService {
  constructor() {
    this.mddResponse = null;
    this.clsResponse = null;
    this.cloudService = new CloudService();
    this.refreshPage = false;
  }

  checkAccessTokenAndLogin(){
    const access_token = CookiesService.getAccessToken() ?? null;
    const noAccessToken = !Boolean(access_token) || access_token.length < 12;
    if (noAccessToken){
      StorageService.clearAll();
      if(window.location.pathname != "/") window.location.href = "/";
      return;
    }

    let ludubot = document.getElementById("luduerror");
    if(ludubot) ludubot.remove();
    const token = AuthStorage.getAccessToken();
    const hasToLogin = access_token != token && !MDD;

    if (hasToLogin) {
      g_loading(true, true);
      AccountApi.read.login(
        { sc: access_token },
        (resp) => {
          g_loading(false);
          this.refreshPage = true;
          this.initClassRoomAuth(resp, true);
        },
        (error) => {
          g_loading(false);
          console.error(error);
        }
      );
    }else{
      this.initClassRoomAuth({ user: { scode: access_token } }, true, false);
    }
  }

  initMddAuth(mddResponse){
    this.mddResponse = mddResponse;
    CookiesService.setAccessToken(mddResponse.scode, true);
    CookiesService.setUaUrl(mddResponse.ua_lms_url);
    CookiesService.setUaLogoUrl(mddResponse.ua_logo_url);

    this.cloudService.getData(ATTR.LAST_PRODUCT, (last_product) => {
      let data = {}
      if (Boolean(last_product)) data.prid = +last_product;
      this.getEnrollmentsData(data);
    });    
  }

  initClassRoomAuth(clsResponse, remember_me = true, navigate = true){
    this.clsResponse = clsResponse;
    CookiesService.setAccessToken(clsResponse.user.scode, remember_me);
   
    this.cloudService.getData(ATTR.LAST_PRODUCT, (last_product) => {
      let lastLicenseIdSelected = undefined
      if (Boolean(last_product)) lastLicenseIdSelected = +last_product;

      this.getUserSubscriptions(lastLicenseIdSelected, navigate);
    })
  }

  getUserSubscriptions(lastLicenseIdSelected, navigate = true){
    UserApi.read.getUserSubscription({}, (resp) => {
      if (resp != null && resp.lil && resp.lil.length){
        const licenses = resp.lil;
        const topMenuMobile = document.querySelector('ml-menu-mobile');
        const topMenuDesktop = document.querySelector('ml-menu');
        let licenseSelected = licenses.find(l => l.prid == lastLicenseIdSelected);

        EnrollStorage.setLicenses(licenses);
        
        if (topMenuDesktop) {
          const PRID = licenseSelected ? licenseSelected.prid : licenses[0].prid;
          topMenuDesktop.addEnrollment(__formatLicenses(licenses), PRID);
        }

        if (!licenseSelected) licenseSelected = licenses[0];
        
        this.cloudService.setData(ATTR.LAST_PRODUCT, licenseSelected.prid, () => {
          licenseSelected.acts = __addDefaultActivities(licenseSelected.acts);

          EnrollStorage.setLicenseSelected(licenseSelected);

          if (this.refreshPage) return window.location.reload();
          if (topMenuMobile) topMenuMobile.reload();
          if (topMenuDesktop) topMenuDesktop.refreshAvatar();
          if(window.location.pathname != URLS.STAT_PAGE && navigate)
            navigateTo(URLS.STAT_PAGE, { fullLoading: true });
        })
      }else{
        const msg = "Ups, parece que no tienes ninguna licencia activa";
        this.#showErrorMsg(msg, "", true);
      }
    })
  }

  getEnrollmentsData(data){
    UserApi.read.getEnrollments(data, (resp) => {
      if(resp.length && resp[0].enr_list.length) 
        return this.#setProductSelected(resp[0]);
      
      if(Boolean(data.prid)) return this.getEnrollmentsData({});
      this.#showErrorMsg("Ups, parece que no tienes ninguna matrícula activa");
    })
  }

  #setProductSelected(product){
    let targetPage = MDD ? URLS.PAGE_MSG : URLS.STAT_PAGE;
    let topMenu = document.querySelector('ml-menu-mobile');
    this.cloudService.setData(ATTR.LAST_PRODUCT, product.prid, () => {
      EnrollStorage.selectLicense(product.prid);
      if (ENV == 'production') targetPage = URLS.STAT_PAGE;
      if (this.refreshPage) return window.location.reload();
      topMenu.reload();
      navigateTo(targetPage, { ...this.mddResponse, fullLoading: true });
    })
  }

  #showErrorMsg(title = "", text = "", logout = true){
    g_ludubot("error", "luduerror");

    let ludubot = document.getElementById("luduerror");
    
    if(ludubot){
      ludubot.target = "50,50";
      ludubot.animate({ duration: 0, msg: { title, text } });
    }

    if (window.location.pathname != URLS.PAGE_MSG) 
      navigateTo(URLS.PAGE_MSG, { empty: true, title });

    if (logout) StorageService.clearAll();
  }
}

export default AuthService;

