import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { rewards } from './../../urls';
import { getGlobalDataForApi } from "./../utils";
import { APP_ID } from 'utils/_.env';

export const setReward = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  data.info = `sent from app: ${APP_ID}`;
  HandleRequest(
    () => ApiService.post(rewards.setReward, data),
    (resp) => success(resp.setReward),
    (_error) => { if(error) error(_error) },
    { api: rewards.setReward, data}
  );
}

export const checkAchievement = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(rewards.checkAchievement, data),
    (resp) => success(resp.checkAchievement),
    (_error) => { if(error) error(_error) },
    { api: rewards.checkAchievement, data}
  );
}

