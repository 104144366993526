import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { stats } from './../../urls';
import { getGlobalDataForApi } from "./../utils";

export const getTreeStats = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(stats.getTreeStats, data),
    (resp) => { success(resp.getTreeStats) },
    (_error) => { if(error) error(_error) },
    { api: stats.getTreeStats, data}
  );
}

export const getStats = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(stats.getStats, data),
    (resp) => { success(resp.getStats) },
    (_error) => { if(error) error(_error) },
    { api: stats.getStats, data}
  );
}

