export const isValidNickName = (value) => {
    let regex = new RegExp("^[A-Za-z\d@\-_ñÑçÇ]{3,15}$");
    return regex.test(value);
}

export const validEmailRegex = (value) => {
    let regex = new RegExp("\\S+@\\S+\\.\\S+");
    return regex.test(value);
}

export const isValidNickEmail = (value) => {
    let regex = new RegExp("^[A-Za-z0-9.\\-_@]{1,150}$");
    return regex.test(value);
}

export const isValidPass = (value) => {
    let has_number = /\d/.test(value);
    let has_letter = /[a-zA-Z]/.test(value);
    let no_spaces = value.indexOf(' ') === -1;
    let pura = /^[A-Za-z\d$@$!%*#?&<>\-_\.+=*()|ñÑçÇ]{8,}$/.test(value);
    return has_letter && has_number && no_spaces && pura;
}
export const onlyLettersAndNumbers = (value) => {
    let has_number = /\d/.test(value);
    let has_letter = /[a-zA-Z]/.test(value);
    let no_spaces = value.indexOf(' ') === -1;
    return has_letter && has_number && no_spaces;
}

export const onlyNumbers = (value) => {
  let regex = new RegExp("^[0-9]{1,}$");
  return regex.test(value);
}

export const isValidDate = (value) => {
    const regexEn = /^\d{4}-(?:0?[1-9]|1[0-2])-([12]\d|0?[1-9]|3[01])$/;
    const regexSp = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    return regexEn.test(value) || regexSp.test(value);
}

export const isEnglishDateFormat = (value) => {
    return value.split('-')[0].length === 4;
}

