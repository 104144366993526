import { alert } from 'components/modals';
import { apiModalMsg as $msg } from 'utils/static_msg';
import { MDD } from 'utils/_.env';
import AuthService from 'services/AuthService';
import { AuthStorage } from 'storage/Auth.storage';
import { EnrollStorage } from '../../storage/Enroll.storage';

const FOR_USER = 'for_user';
const EXCEPTION = 'exception';
const SPECIAL_CODE_EXCEPTION = ["enrnf"];
const ERROR_TO_LOGOUT = ["invs", "exps"];

const _printAlert = (code, msg, type) => {
  let body_html = `<div class="text-center">
        <p class="ml_paragraph">${$msg.body_title}</p>
        <p class="ml_paragraph">${msg}</p>
        <p class="ml_paragraph">Código: <strong>${code}</strong></p>
      </div>`

  if(type === FOR_USER){
    body_html = `<div>
        <p class="ml_paragraph text-center">${msg}</p>
      </div>`
  }

  alert({
    title: type === FOR_USER ? "¡Atención!" : $msg.title,
    body_html
  })
}

const _checkSpecialCodeError = (code) => {
  if(code === "enrnf"){
    const authService = new AuthService();
    authService.getEnrollmentsData({});
  }
}

const _forceLogout = () => {
  localStorage.removeItem('AUTH');
  sessionStorage.removeItem('page-state');
  location.reload();
}

export const codeInterpreter = (code, data = {}) => {
  let { user_msg, type_msg } = data;

  if (ERROR_TO_LOGOUT.includes(code)) return _forceLogout();
  if(data.type_msg !== EXCEPTION) _printAlert(code, user_msg, type_msg);
  if (SPECIAL_CODE_EXCEPTION.includes(code)) return _checkSpecialCodeError(code);

  return { msg: data.user_msg, data, code }
}

export const genericErrorRequestMsg = (source, data={}) => {
  let type = source === 'from_api' ? 'Servidor' : 'WEB';

  if (data.result && !["bpw", "eof"].includes(data.result)) alert({
    title: $msg.title,
    body_html: `<div class="text-center">
        <p class="ml_paragraph">${$msg.body_title}</p>
        <p class="ml_paragraph">${$msg.generic_msg}</p>
        <p class="ml_paragraph">Origen: <strong>${type}</strong></p>
    </div>`
  })

  return { msg: $msg.title, data, source }
}

export const getGlobalDataForApi = (data) => {
  const { ua, user } = AuthStorage.getAuth();
  const { licenseId } = EnrollStorage.getEnrollSelected();
  
  data.person_id = user.pid;
  data.pid = user.pid;
  if(!Boolean(data.eid)) data.eid = licenseId;
  if(MDD) data.partner_id = ua.partner_id
}

