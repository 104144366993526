import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { rewards } from './../../urls';
import { getGlobalDataForApi } from "./../utils";

export const getRewards = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(rewards.getRewards, data),
    (resp) => { success(resp.getRewards) },
    (_error) => { if(error) error(_error) },
    { api: rewards.getRewards, data}
  );
}

export const getAchievements = (data, success, error) => {
  getGlobalDataForApi(data);
  return HandleRequest(
    () => ApiService.post(rewards.getAchievements, data),
    (resp) => { if (success) success(resp.getAchievements) },
    (_error) => { if (error) error(_error) },
    { api: rewards.getAchievements, data }
  );
}

export const getRankings = (data, success, error) => {
  getGlobalDataForApi(data);
  return HandleRequest(
    () => ApiService.post(rewards.getRankings, data),
    (resp) => { if (success) success(resp.getRankings) },
    (_error) => { if (error) error(_error) },
    { api: rewards.getRankings, data }
  );
}
