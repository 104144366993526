import { SettingsStorage } from 'storage/Settings.storage';

const UserConfig = () => {
  if (SettingsStorage.getFirstTime()) {
    let BODY = document.getElementById('main_classroom_wrap');
    let isCompressed = BODY.classList.contains('ml_menu_compressed');
    if(!isCompressed) setTimeout(() => {
      // To Start sidebar compressed uncomment this line
      //BODY.classList.add('ml_menu_compressed');
      
      SettingsStorage.setFirstTime();
      SettingsStorage.setSidebarOpen(true);
    }, 2000)
  }

  const BODY = document.getElementById('main_classroom_wrap');
  BODY.classList.toggle('ml_menu_compressed', !SettingsStorage.getSidebarOpen());
}

export default UserConfig;
