import { defaultTheme as $t } from 'services/updateTheme';
import { URL_STATIC } from 'utils/_.env.js';

const INITIAL_STATE = {
  user: {},
  token: '-',
  changePass: false,
  ua: {
    partner_id: 0,
    attr: {},
    theme: {
      primary: $t.col1.primary,
      lprimary: $t.col1.lprimary,
      primary2: $t.col1.primary2,
      primary3: $t.col1.primary3,
      primary4: $t.col1.primary4,
      primary5: $t.col1.primary5,
      accent: $t.col2.accent,
      accent2: $t.col2.accent2,
      accent3: $t.col2.accent3,
      accent4: $t.col2.accent4,
      accent5: $t.col2.accent5,
      laccent: $t.col2.laccent,
      secondary: $t.col3.secondary,
      lsecondary: $t.col3.lsecondary,
      secondary2: $t.col3.secondary2,
      secondary3: $t.col3.secondary3,
      secondary4: $t.col3.secondary4,
      secondary5: $t.col3.secondary5,
      warning: $t.col4.warning,
      lwarning: $t.col4.lwarning,
      warning2: $t.col4.warning2,
      warning3: $t.col4.warning3,
      warning4: $t.col4.warning4,
      warning5: $t.col4.warning5,
      success: $t.col5.success,
      lsuccess: $t.col5.lsuccess,
      success2: $t.col5.success2,
      success3: $t.col5.success3,
      success4: $t.col5.success4,
      success5: $t.col5.success5,
    },
    ua_logo_url: "https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/statics/meludus/meludus.svg",
    ua_lms_url: "",
    ua_subdomain: "",
    ua_name: "",
  }
}

const KEY = "AUTH";

export class AuthStorage {

  // SETTERS:
  static setAuth(data) {
    const currentAuth = this.#get();

    const newAuthData = {
      ...currentAuth,
      user: {
        pid: parseInt(data.person),
        email: "", // TODO: ASK FOR EMAIL
        nick: data.nick
      },
      token: data.scode,
      changePass: false, // TODO: ASK FOR CHANGE PASSWORD
    }

    this.#set(newAuthData);
  }

  static setUAPartner(data) {
    const currentAuth = this.#get();

    const newAuthData = {
      ...currentAuth,
      ua: {
        partner_id: data.partner_id,
        attr: data.custom_attributes,
        theme: data.theme,
        ua_logo_url: data.ua_logo_url,
        ua_lms_url: data.ua_lms_url,
        ua_subdomain: data.ua_subdomain,
        ua_name: data.ua_name,  
      }
    }

    this.#set(newAuthData);
  }


  // GETTERS:
  static getAuth() {
    return this.#get();
  }

  static getUser() {
    return this.#get().user || {};
  }

  static getAccessToken() {
    return this.#get().token || '-';
  }

  static getUA() {
    return this.#get().ua;
  }

  static getAvatar() {
    const PID = this.getUser().pid;
    return `${URL_STATIC}avatar/${PID}-thumbnail.png?${new Date().getTime()}`;
  }


  // OTHERS:
  static isLogged() {
    return this.#get().token !== '-';
  }

  static clear() {
    this.#set(INITIAL_STATE);
  }


  static #set(data) {
    localStorage.setItem(KEY, JSON.stringify(data));
  }

  static #get() {
    return JSON.parse(localStorage.getItem(KEY)) || INITIAL_STATE;
  }
}