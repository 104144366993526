import {router} from 'routing';
import AuthService from 'services/AuthService';
import UserConfig from 'services/UserConfig';
import { EnrollStorage } from 'storage/Enroll.storage';

const App = async() => {
  const updateMemory = localStorage.getItem("ENROLL");

  if (updateMemory?.includes("enrollSelected")) {
    await router();
    const authService = new AuthService();
    authService.checkAccessTokenAndLogin();
    UserConfig();
  } else {
    localStorage.clear();
    sessionStorage.clear();
    EnrollStorage.clear();
    location.reload();
  }
}

export default App;
