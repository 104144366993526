import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { enroll } from './../../urls';
import { getGlobalDataForApi } from "./../utils";

export const setCustomTree = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(enroll.setCustomTree, data),
    (resp) => success(resp.setCustomTree),
    (_error) => { if(error) error(_error) },
    { api: enroll.setCustomTree, data}
  );
}

export const setStudyGoal = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(enroll.setStudyGoal, data),
    (resp) => success(resp.setCustomTree),
    (_error) => { if(error) error(_error) },
    { api: enroll.setStudyGoal, data}
  );
}

