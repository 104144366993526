export const DEBUGGER = process.env.DEBUGGER === 'true';
export const PCEK = process.env.PCEK;
export const CRYPT_KEY = process.env.CRYPT_KEY;
export const BASE_URL= process.env.API_URL;
export const URL_STATIC= process.env.URL_STATIC;
export const URL_LOG = "https://api-dev.meludus.com/m2/bsi/";
export const MDD = process.env.MDD === 'true';
export const APP_ID = parseInt(process.env.APP_ID);
export const ENV = process.env.ENV;
export const E_COMMERCE = process.env.E_COMMERCE;
export const UNITY_ENV = process.env.UNITY_ENV === 'true';

