import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { book } from './../../urls';
import { getGlobalDataForApi } from "./../utils";

export const getItems = (data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  return HandleRequest(
    () => ApiService.post(book.getItems, data),
    (resp) => { if(success) success(resp.getItems) },
    (_error) => { if(error) error(_error) },
    { api: book.getItems, data}
  );
}

//export const getItems = async(data, success, error) => {
  //getGlobalDataForApi(data);
  //data.pid = data.person_id;
  //HandleRequest(
    //() => ApiService.post(book.getItems, data),
    //(resp) => { success(resp.getItems) },
    //(_error) => { if(error) error(_error) },
    //{ api: book.getItems, data}
  //);
//}

export const getParents = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(book.getParents, data),
    (resp) => { success(resp.getParents) },
    (_error) => { if(error) error(_error) },
    { api: book.getParents, data}
  );
}


