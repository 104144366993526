import { treeItem as $, item_types as $it } from 'utils/const';

const INITIAL_STATE = {
  programVersion: undefined,
  customVersion: undefined,
  customTree: undefined
}

const KEY = "TREE";

export class TreeStorage {

  // SETTERS:
  static setTree(data) {
    const customTree = data.custom;
    const currentData = this.#get();
    this.#set({ ...currentData, customTree: customTree });
  }

  static setCustomTreeVersion(customVersion) {
    const currentData = this.#get();

    this.#set({ ...currentData, customVersion });
  }

  static setCustomTree(data) {
    const currentData = this.#get();

    this.#set({ 
      ...currentData, 
      customVersion: data.custom_tree_version,
      customTree: data.custom_tree,
    });
  }

  static setProgramVersion(data) {
    const currentData = this.#get();

    this.#set({ ...currentData, programVersion: data.current_version });
  }

  // GETTERS:
  static getCustomTree() {
    return this.#get().customTree;
  }

  static get() {
    return this.#get();
  }


  // OTHERS:
  static clear() {
    this.#set(INITIAL_STATE);
  }

  static isLaw() {
    const tree = this.#get().customTree;
    return tree[$.children][0][$.typ] === $it.PROGRAM_KB;
  }


  static #set(data) {
    localStorage.setItem(KEY, JSON.stringify(data));
  }

  static #get() {
    return JSON.parse(localStorage.getItem(KEY)) || INITIAL_STATE;
  }
}