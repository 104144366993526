import { toNode } from 'utils/html_creator';
import view from './layout.html';

const alert = (data={}) => {
  let $view = toNode(view);
  Handler($view, data);
  document.querySelector('body').appendChild($view);
}

const Handler = (layout, data) => {

  let title = layout.querySelector('#cmp_alert_title'),
    body = layout.querySelector('#cmp_alert_info'),
    btn = layout.querySelector('#cmp_ok'),
    card = layout.firstElementChild;

  card.classList.add(`ml-modal-card-${data.size ? data.size : 'sm'}`);

  if(data.title){
    let html = `<p class="ml_paragraph ml_text-center ml_strong">${data.title}</p>`
    title.innerHTML = html;
  }else{
    if(data.title_html) title.innerHTML = data.title_html;
  }

  if(data.body){
    let html = `<p class="ml_paragraph ml_text-center">${data.body}</p>`
    body.innerHTML = html;
  }else{
    if(data.body_html) body.innerHTML = data.body_html;
  }
  if(data.btn) btn.innerText = data.btn;
  
  layout.addEventListener('click', ({ target }) => {
    if(target.matches('#cmp_close_alert')){
      layout.remove();
    }
    
    if(target === btn){
      if(data.ok) data.ok();
      layout.remove();
    }
  });
}

export default alert;

