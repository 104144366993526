import { render, html } from 'lit-html';
import { toCamelCase } from 'utils/to-camel-case';

class WebComponent extends HTMLElement {
  /** Triggered when the provided props have changed */
  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.__initialized) {
      if (oldValue !== newValue) {
        this[toCamelCase(attributeName)] = newValue
        this.render();
      }
    }
  }

  /** Initializer of component definition */
  constructor(styles, shadowDOM = true, component = "***") {
    super();
    this.__styles = styles;
    this.#__init({ shadowDOM, component });
  }

  initialize() { }

  /** Element created and initialized in DOM */
  connectedCallback() {
    this.initialize();
    this.__initialized = true;
    this.render();
  }

  disconnectedCallback(){ }

  /** ______________________ RENDER ______________________________ */
  template() {
    return /* template */html` `;
  }
  /** ____________________________________________________________ */

  /**
   * ========================= PRIVATE FUNCTIONS ===========================
   * (Don't modify the functions below. Copy-paste to other web components)
   * ==================================================================== */

  /** Init web component config to be executed in constructor */
  #__init({ shadowDOM = true, component } = {}) {
    this.__isShadowDOM = shadowDOM;
    if (this.__isShadowDOM) this.root = this.attachShadow({ mode: 'open' });
    this.render();
    this.#__attachStyle(component);
    this.__initialized = false;
  }

  /** Inject processed SCSS Style */
  #__attachStyle(component) {
    if(component !== "***"){
      let exist = document.querySelector(`[data-id="_${component}"]`)
      if(exist) return;
    }
    let style = document.createElement('style');
    style.dataset.id = "_" + component;
    style.textContent = this.__styles
      .replace(/(\/)([\w?\d?])/g, '\\/$2')              // Fix for tailwind 'w-1/2' classes
      .replace(/(\[)(\w?\d?(?:rem|px|%)?)/g, '\\[$2\\') // Fix for tailwind custom 'w-[50px]' classes
      .replace(/(\.\w+)(\:)(\w+)/g, '$1\\:$3');         // Fix for tailwind state classes 'hover:bg-primay'
    const appendStyleTo = this.__isShadowDOM
      ? this.root
      : document.head || document.getElementsByTagName('head')[0];

    appendStyleTo.prepend(style);
  }


  /** Internal function to update the UI based (re-render and first render) */
  render() {
    const attachTo = this.__isShadowDOM ? this.root : this;
    render(this.template(), attachTo);
  }
  /** ============================================================= */
}

export default WebComponent;

