import { loadData, toNode } from 'utils/html_creator';
import view from './layout.html';
import { treatLuduFace, getBtnClass } from './utils';

const LuduModal = (flag=true, data = {}) => {
  data.title ??= "Titulo de modal";
  data.text ??= "Contenido informativo";
  data.face ??= "reposo";
  data.btns ??= [{
    id: 'lm_close',
    text: 'cerrar',
    cls: 'primary',
    funcName: "",
    root: true
  }];
  
  let element = document.getElementById("luduModal");

  if (element && flag) return;
  if(!flag){
    if(element){
      element.open = "false";
      setTimeout(() => element.remove(), 1000);
    }
  }else{
    let $view = toNode(loadData(view, {
      title: data.title,
      text: data.text,
      face: treatLuduFace(data.face),
    }));
    data.btns.forEach((btn, index) => {
      btn.id ??= `ludu_btn_${index}`;
      let $btn = toNode(`
        <button id="${btn.id}" class="${getBtnClass(btn.cls)}">${btn.text}</button>
      `)
      if(!btn.root){
        $btn.addEventListener('click', (e) => {
          g_loadInstance()[btn.funcName]();
        })
      }
      $view.appendChild($btn);
    })
    document.querySelector('body').appendChild($view);
    setTimeout(() => $view.open = "true", 500);
  }
}

export default LuduModal;

//loadLuduModal(true, {btns: [{id: 'close', text: "cerrar", root: false}, {text: "guardar", root: true}, {text: "otros", root: false}]})

