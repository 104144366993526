import { routes, notFound } from "./urls";
import Middleware from './middleware';
import { onlyNumbers } from 'utils/validator';
import { MDD } from 'utils/_.env';
import { AuthStorage } from "storage/Auth.storage";

const root = document.getElementById('root');
const TEMPORAL_STATE = "temporal-state";

const navigateTo = async(url, params) => {
  history.pushState(null, null, url);
  router(params);
};

const openNewTab = (url, params={}) => {
  if(Object.keys(params).length > 0)
    localStorage.setItem(TEMPORAL_STATE, JSON.stringify(params));
  window.open(url, '_blank');
}

const navigateNextSection = async (view, params = {}) => {
  sessionStorage.removeItem('page-state');
  const $view = new view(params);
  root.firstElementChild.innerHTML = "";
  root.firstElementChild.appendChild(await $view.getElement());
}

const extraData = () => {
  let params = {};
  let url_params = location.search.substr(1).split('&');
  let temporalState = JSON.parse(localStorage.getItem(TEMPORAL_STATE));
  if(url_params[0].length === 0) url_params = [];
  
  url_params.forEach(param => {
    let data = param.split('=');
    let value = onlyNumbers(data[1]) ? parseInt(data[1]) : data[1];
    params[data[0]] = value;
  });

  let path = location.pathname;
  if(path.split('/').length > 2){
    path = `/${path.split('/').pop()}`;
  }
  
  if(Boolean(temporalState)){
    params = { ...params, ...temporalState };
    localStorage.removeItem(TEMPORAL_STATE);
  }

  return {
    url: path + location.search,
    path,
    params: { ...params, path }
  }
}

const addPrefix = (path) => {
  if(!AuthStorage.isLogged()) return '/';
  const ua = AuthStorage.getUA();
  path = MDD ? `/${ua.ua_subdomain}${path}` : path;
  history.pushState(null, null, path);
}

const router = async (params = {}) => {
  document.querySelector('body').removeAttribute('class');
  let dataFromUrl = extraData();
  params = { ...params, ...dataFromUrl.params };

  let path = await Middleware(dataFromUrl.path);
  if(path != dataFromUrl.path){
    addPrefix(path);
    params = { ...params, path }
  }else{
    addPrefix(dataFromUrl.url);
  }

  let route = routes.find(route => route.path == path);
  if (!route) route = notFound;

  // const { view, config } = route;
  // const $view = new view(params, config);

  let $view = await route.getView();
  $view = new $view(params, route.config);

  root.firstElementChild.innerHTML = "";
  root.firstElementChild.appendChild(await $view.getElement());

  document.dispatchEvent(new CustomEvent('viewLoaded', { 
    detail: { path, params },
    composed: true
  }));
};

export {
  router,
  navigateTo,
  navigateNextSection,
  openNewTab,
}

