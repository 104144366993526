import { ACHIEVEMENT, checkAchievement } from "services/AchievementService";
import { ATTR, CloudService } from "services/CloudService";

const INITIAL_STATE = {
  assistance: 0,
  assistanceDate: '',
  approveTest: 0,
}

const KEY = "ACHIEVEMENT";

export class AchievementStorage {

  // SETTERS:
  static setAssistance() {
    const cloudService = new CloudService();
    const todayDate = new Date(Date.now()).toLocaleDateString();
    const currentData = this.#get();

    let changed = false;

    if (currentData.assistanceDate.length) {
      const storageDate = new Date(currentData.assistanceDate).getTime();
      const currentDate = new Date(todayDate).getTime();

      if (currentDate > storageDate) {
        currentData.assistance = currentData.assistance + 1;
        currentData.assistanceDate = todayDate;
        currentData.approveTest = 0;
        changed = true;
      }
    } else {
      currentData.assistance = 1;
      currentData.assistanceDate = todayDate;
      changed = true;
    }

    if (changed) {
      this.#set(currentData);
      cloudService.setData(ATTR.ACHIEVEMENT, JSON.stringify(currentData));
      checkAchievement(ACHIEVEMENT.ASISTENCIA, {});
    }
  }

  static setApproved() {
    const cloudService = new CloudService();
    const todayDate = new Date(Date.now()).toLocaleDateString();
    const currentData = this.#get();

    let changed = false;

    if (currentData.assistanceDate.length) {
      const storageDate = new Date(currentData.assistanceDate).getTime();
      const currentDate = new Date(todayDate).getTime();

      if (storageDate == currentDate) {
        currentData.approveTest = currentData.approveTest + 1;
        changed = true;
      }
    } else {
      currentData.assistanceDate = todayDate;
      currentData.approveTest = 1;
      changed = true;
    }

    if (changed) {
      this.#set(currentData);
      cloudService.setData(ATTR.ACHIEVEMENT, JSON.stringify(currentData));
    }

    if (currentData.approveTest > 5) checkAchievement(ACHIEVEMENT.EMPOLLON, {});
  }


  // GETTERS:
 


  // OTHERS:
  static clear() {
    this.#set(INITIAL_STATE);
  }


  static #set(data) {
    localStorage.setItem(KEY, JSON.stringify(data));
  }

  static #get() {
    return JSON.parse(localStorage.getItem(KEY)) || INITIAL_STATE;
  }
}