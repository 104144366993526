import { RewardsApi } from 'api_connection';
import achievementsModal from '../components/global/achievements-modal/build';
import { AchievementStorage } from 'storage/Achievement.storage';
import { AuthStorage } from 'storage/Auth.storage';

// DOC: https://docs.google.com/spreadsheets/d/1G8FR6iVy7LFQJnVMGRqVfvUK06Y35r_opNsA3YkEpuw/edit#gid=935242601
export const ACHIEVEMENT = {
  END_TEST: 'end_test',
  POINTS: 'points',
  TENAZ: 'Tenaz',
  MILLONETI: 'Milloneti',
  WINNER: 'Winner',
  MADRUGADOR: 'Madrugador',
  NOCTAMBULO: 'Noctambulo',
  ASISTENCIA: 'Asistencia',
  MELUDUS_ADDICT: "Meludus_Addict",
  EMPOLLON: "Empollon",
}

const checkPoints = (score, questions = 0) => {
  score = +score.split(',')[0]
  if(score == 10){
    RewardsApi.write.checkAchievement({ evt: "Test_10" }, (resp) => {
      if(resp.length > 0){
        loadAchievementsModal(resp);
      }
    })
  }
  if(score >= 7 && score < 10){
    RewardsApi.write.checkAchievement({ evt: "Test_7" }, (resp) => {
      if(resp.length > 0){
        loadAchievementsModal(resp);
      }
    })
  }
  if(score >= 5){
    RewardsApi.write.checkAchievement({ evt: "Superdotado" }, (resp) => {
      if(resp.length > 0){
        loadAchievementsModal(resp);
      }
    })
  }
  if(score >= 5 && questions >= 50){
    AchievementStorage.setApproved();
  }
}

const assistanceAndAddict = () => {
  RewardsApi.write.checkAchievement({ evt: ACHIEVEMENT.ASISTENCIA }, (resp) => {
    if(resp.length > 0){
      loadAchievementsModal(resp);
    }
    RewardsApi.write.checkAchievement({ evt: ACHIEVEMENT.MELUDUS_ADDICT }, (resp) => {
      if(resp.length > 0){
        loadAchievementsModal(resp);
      }
    })
  })
}

const cases = {
  end_test: ({ score, questions }) => checkPoints(score, questions),
  Tenaz: () => {
    RewardsApi.write.checkAchievement({ evt: ACHIEVEMENT.TENAZ }, (resp) => {
      if(resp.length > 0){
        loadAchievementsModal(resp);
      }
    })
  },
  Empollon: () => {
    RewardsApi.write.checkAchievement({ evt: ACHIEVEMENT.EMPOLLON }, (resp) => {
      if(resp.length > 0){
        loadAchievementsModal(resp);
      }
    })
  },
  points: () => {
    RewardsApi.write.checkAchievement({ evt: ACHIEVEMENT.MILLONETI }, (resp) => {
      // if(resp.length > 0){
      if(resp.length > 0){
        loadAchievementsModal(resp);
      }
      RewardsApi.write.checkAchievement({ evt: ACHIEVEMENT.WINNER }, (resp) => {
        // if(resp.length > 0){
          if(resp.length > 0){
          loadAchievementsModal(resp);
        }
      })
    })
  },
  Asistencia: () => {
    let date = new Date(),
      currentSeconds = date.getSeconds() + (date.getMinutes() * 60) + (date.getHours() * 3600),
      ZERO_AM = 0,
      SIX_AM = 21600,
      EIGHT_AM = 28800;
    if(currentSeconds >= ZERO_AM && currentSeconds < SIX_AM){
      RewardsApi.write.checkAchievement({ evt: ACHIEVEMENT.NOCTAMBULO }, (resp) => {
        if(resp.length > 0){
          loadAchievementsModal(resp);
        }
        assistanceAndAddict();
      })
    }
    if(currentSeconds >= SIX_AM && currentSeconds <= EIGHT_AM){
      RewardsApi.write.checkAchievement({ evt: ACHIEVEMENT.MADRUGADOR }, (resp) => {
        if(resp.length > 0){
          loadAchievementsModal(resp);
        }
        assistanceAndAddict();
      })
    }
  }
}

export const loadAchievementsModal = (resp) => {// need a object
  for (let i = 0; i <= (resp.length/3)-1; i++) {
    achievementsModal(true, {
      userName: AuthStorage.getUser().nick, 
      achievementImgPath: resp[0+(i*3)].event,
      level: resp[2+(i*3)].
      score,
      achievementName: resp[2+(i*3)].achievement_name, 
      ludins: resp[1+(i*3)].credits, 
      ludus: resp[0+(i*3)].points, 
      description: resp[2+(i*3)].info
    });
  }
}

export const checkAchievement = (type, data) => cases[type](data);

