import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { test } from './../../urls';
import { cards } from './dummies';

export const getCards = async(data, success, error) => {
  let resp = await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(cards.cards);
    }, 1 + (Math.random() * 10 / 4))
  })
  success(resp);
}

