import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { account } from './../../urls';
import { APP_ID } from 'utils/_.env';

export const userSetAttributes = (data, success, error) => {
    HandleRequest(
        () => ApiService.post(account.userSetAttributes, data),
        (dataSuccess) => success({ result: dataSuccess.usr_set_attributes_data }),
        (dataError) => error(dataError),
        { api: account.userSetAttributes, data }
    );
}

export const signUp = (data, success, error) => {
    data.app = APP_ID;
    data.partner_id = 1000;
    HandleRequest(
        () => ApiService.post(account.signUp, data),
        (successData) => success(successData.signup_data),
        (errorData) => error(errorData),
        { api: account.signUp, data }
    )
}

export const signUpCheck = (data, success, error) => {
    data.app = APP_ID;
    data.partner_id = 1000;
    HandleRequest(
        () => ApiService.post(account.signUpCheck, data),
        (successData) => success(successData),
        (errorData) => error(errorData),
        { api: account.signUpCheck, data }
    )
}

