import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { account } from './../../urls';
import { UpdateTheme } from 'services/updateTheme';
import { themeFormat } from './format';
import { APP_ID } from 'utils/_.env';
import { AuthStorage } from 'storage/Auth.storage';
import { StorageService } from 'storage/Storage.storage';

export const login = (data, success, error) => {
    HandleRequest(
        () => ApiService.post(account.login, { app: APP_ID, ...data }),
        (resp) => {
            AuthStorage.setAuth(resp.login_data);
            success({ user: resp.login_data });
        }, (e_data) => error(e_data),
        { api: account.login, data }
    );
}

export const logout = (data, success, error) => {
    HandleRequest(
        () => ApiService.post(account.logout, data),
        (dataSuccess) => {
            let status = dataSuccess.logout;
            if (status === 'ok') {
                StorageService.clearAll();
                success(status);
            } else {
                success('error');
            }
        }, (dataError) => error(dataError),
        { api: account.logout, data }
    );
}

export const forgotPassword = (data, success, error) => {
    HandleRequest(
        () => ApiService.post(account.forgotPassword, data),
        (dataSuccess) => {
            let { e_email } = dataSuccess.login_forgot_password_data;
            success({ email: e_email });
        }, (dataError) => error(dataError),
        { api: account.forgotPassword, data }
    );
}

export const checkFlashLink = (data, success, error) => {
    HandleRequest(
        () => ApiService.post(account.checkFlashLink, data),
        (resp) => {
            let user = resp.checkFlashLink.result_data;
            AuthStorage.setAuth(user);
            AuthStorage.setUAPartner({
                partner_id: parseInt(data.partner_id),
                theme: UpdateTheme(themeFormat(user.ua_custom_colors)),
                ...user,
            })
            success(user);
        }, (e_data) => error(e_data),
        { api: account.checkFlashLink, data }
    );
}

export const loginMdd = (data, success, error) => {
    HandleRequest(
        () => ApiService.post(account.login, { app: APP_ID, ...data }),
        (resp) => {
            let user = resp.login_data;
            AuthStorage.setAuth(user);
            AuthStorage.setUAPartner({
                partner_id: parseInt(data.partner_id),
                theme: UpdateTheme(themeFormat(user.ua_custom_colors)),
                ...user,
            })
            success(user);
        }, (e_data) => error(e_data),
        { api: account.login, data }
    );
}

