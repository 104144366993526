import { ENV } from "utils/_.env";

const COOKIES_KEYS = {
  ACCESS_TOKEN: 'at',
  UA_URL: 'ua_url',
  UA_LOGO_URL: 'ua_logo_url',
}

const DOMAIN = ".meludus.com";
const SUFFIX = "_dev";

export class CookiesService {

  static clearAll = () => {
    this.#remove(COOKIES_KEYS.ACCESS_TOKEN);
    this.#remove(COOKIES_KEYS.UA_URL);
    this.#remove(COOKIES_KEYS.UA_LOGO_URL);
  }

  static setAccessToken = (value, remember_me=false) => {
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 360);

    this.#set(COOKIES_KEYS.ACCESS_TOKEN, value, remember_me ? expires : null);
  }

  static getAccessToken = () => {
    return this.#get(COOKIES_KEYS.ACCESS_TOKEN);
  }

  static removeAccessToken = () => {
    this.#remove(COOKIES_KEYS.ACCESS_TOKEN);
  }


  static setUaUrl = (value) => {
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 360);
    this.#set(COOKIES_KEYS.UA_URL, value, expires);
  }

  static getUaUrl = () => {
    return this.#get(COOKIES_KEYS.UA_URL);
  }

  static removeUaUrl = () => {
    this.#remove(COOKIES_KEYS.UA_URL);
  }


  static setUaLogoUrl = (value) => {
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 360);
    this.#set(COOKIES_KEYS.UA_LOGO_URL, value, expires);
  }

  static getUaLogoUrl = () => {
    return this.#get(COOKIES_KEYS.UA_LOGO_URL);
  }

  static removeUaLogoUrl = () => {
    this.#remove(COOKIES_KEYS.UA_LOGO_URL);
  }


  static #set(key, data, expires) {
    if (ENV != "production") key += SUFFIX;

    const domain = document.location.hostname == "localhost" ? "localhost" : DOMAIN;

    let cookiesOptions = `${key}=${data}; domain=${domain}; path=/`
    if (expires) cookiesOptions = cookiesOptions + `; expires=${expires};`

    document.cookie = cookiesOptions;
  }

  static #get(key) {
    if (ENV != "production") key += SUFFIX;

    let value = document.cookie.match("(^|[^;]+)\\s*" + key + "\\s*=\\s*([^;]+)")
    value = value ? value.pop() : undefined;

    if (value != undefined) value = value.replaceAll("%22", "");

    return value;
  }


  static #remove(key) {
    if (ENV != "production") key += SUFFIX;

    const domain = document.location.hostname == "localhost" ? "localhost" : DOMAIN;
    document.cookie = `${key}=; expires=Fri, 26 Nov 2022 20:02:44 GMT; domain=${domain}`;
  }
}