import Localbase from 'localbase'
import { EnrollStorage } from 'storage/Enroll.storage';

const singleton = 'indexedDB';
const singletonEnforcer = 'indexedDB_ENFORCER';

class IndexedDB {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }
    this.client = new Localbase("db");
    this.eid = null;
  }

  getEid = () => {
    if(this.eid == null){
      const { licenseId } = EnrollStorage.getEnrollSelected();
      this.eid = `${licenseId}_kbs`;
      console.log("NEW BD ID: ", this.eid);
    }
    return this.eid;
  }

  resetEid = () => {
    this.eid = null
    console.log("RESET BD ID: ", this.eid);
  }

  reset = () => {
    console.log("Reset DB COLLECTION");
    return this.client.collection(this.getEid()).delete();
  }
  
  getCollection = () => {
    return this.client.collection(this.getEid()).get();
  }

  getKb = (nodeId, callback) => {
    return this.client.collection(this.getEid())
      .doc({ nodeId })
      .orderBy("order")
      .get().then(kbs => callback(kbs));
  }

  addKb = (nodeId, tree) => {
    if (Array.isArray(tree)) {
      tree.forEach((_tree, index) => {
        this.client.collection(this.getEid()).add({
          id: `${nodeId}-${index}`,
          nodeId,
          order: index,
          tree: JSON.stringify(_tree)
        });
      })
      return true;
    }

    return this.client.collection(this.getEid()).add({
      id: `${nodeId}-0`,
      nodeId,
      order: 0,
      tree: JSON.stringify(tree)
    })
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new IndexedDB(singletonEnforcer);
    }

    return this[singleton];
  }
}

export default IndexedDB.instance;



