import { onlyNumbers } from 'utils/validator';
import { MDD } from 'utils/_.env';
import { URLS } from './urls';
import { AuthStorage } from 'storage/Auth.storage';

const REGISTER = '/registro';

const Middleware = async(path) => {
   if(MDD){
      return _middlewareMdd(path);
   }else{
      return _middlewareClassroom(path);
   }
}

const _middlewareMdd = async(path) => {
   if (path === REGISTER) return path;

   if (AuthStorage.isLogged()) {
      let { flc, p } = extraData();
      if(flc && p) return path;
      return path === URLS.ROOT ? URLS.STAT_PAGE : path;
   } else {
      return path === URLS.PAGE_MSG ? path : URLS.ROOT;
   }
}

const _middlewareClassroom = async(path) => {
   if(path === REGISTER) return path;

   if (AuthStorage.isLogged()) {
      return path === URLS.ROOT ? URLS.STAT_PAGE : path;
   }else{
      return URLS.ROOT;
   }
}

const extraData = () => {
  let params = {};
  let url_params = location.search.substr(1).split('&');
  if(url_params[0].length === 0) url_params = [];
  
  url_params.forEach(param => {
    let data = param.split('=');
    let value = onlyNumbers(data[1]) ? parseInt(data[1]) : data[1];
    params[data[0]] = value;
  });

  return params
}

export default Middleware;

