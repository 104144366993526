import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { test } from './../../urls';
import { getGlobalDataForApi } from "./../utils";

export const getFolderList = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.getFolderList, data),
    (resp) => { if (success) success(resp.getFolderList); },
    (_error) => { if (error) error(_error); },
    { api: test.getFolderList, data }
  );
}

export const getTemplateNodes = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.getTemplateNodes, data),
    (resp) => success(resp.getTemplateNodes),
    (_error) => {if(error) error(_error)},
    { api: test.getTemplateNodes, data }
  );
}

export const readTemplate = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.readTemplate, data),
    (resp) => success(resp.readTemplate),
    (_error) => {if(error) error(_error)},
    { api: test.readTemplate, data }
  );
}

export const getTemplateList = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.getTemplateList, data),
    (resp) => {success(resp.getTemplateList)},
    (_error) => { if(error) error(_error) },
    { api: test.getTemplateList, data}
  );
}

export const getRecentTests = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.getRecentTests, data),
    (resp) => {success(resp.getRecentTests)},
    (_error) => { if(error) error(_error) },
    { api: test.getRecentTests, data}
  );
}

export const getTestList = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.getTestList, data),
    (resp) => {success(resp.getTestList)},
    (_error) => { if(error) error(_error) },
    { api: test.getTestList, data}
  );
}

export const getExtQuestion = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.getExtQuestion, data),
    (resp) => {success(resp.getExtQuestion)},
    (_error) => { if(error) error(_error) },
    { api: test.getExtQuestion, data}
  );
}

export const getTestResult = async(data, success, error) => {
  getGlobalDataForApi(data);
  HandleRequest(
    () => ApiService.post(test.getTestResult, data),
    (resp) => {success(resp.getTestResult)},
    (_error) => { if(error) error(_error) },
    { api: test.getTestResult, data}
  );
}

