import { html } from 'lit-html';
import styles from './_.styles.scss';
import { navigateTo } from 'routing';
import { URL_STATIC } from 'utils/_.env.js';
import { RewardsApi } from 'api_connection';
import WebComponent from './../../WebComponent';
import { E_COMMERCE } from 'utils/_.env';
import { StorageService } from 'storage/Storage.storage';
import { AuthStorage } from 'storage/Auth.storage';
import { EnrollStorage } from 'storage/Enroll.storage';

class MobileMenu extends WebComponent {
  static get observedAttributes() {
    return [ 'is-open' ];
  }

  get isOpen() {return this.__isOpen}
  set isOpen(value) {
    this.__isOpen = value === 'true' || value === true;
    this.render();
  }

  constructor() {
    super(styles, false, 'ml-menu-mobile');
    this.__body = document.body;
  }

  initialize() {
    this.__avatar = `${URL_STATIC}avatar/avatar-thumbnail.png`;
    this.__rewards = { achievements: 0, likes: 0, ludus: 0, ludis: 0 };
    const body = this.querySelector(".mobile-menu-body");

    document.addEventListener('clickShowMenuMobile', this.__openMenuMobile.bind(this), true);
    body.addEventListener("scroll", this.__scrollHandler.bind(this), true);
  }

  disconnectedCallback(){
    const body = this.querySelector(".mobile-menu-body");

    document.removeEventListener('clickShowMenuMobile', this.__openMenuMobile, true);
    body.removeEventListener("scroll", this.__scrollHandler.bind(this), true);
  }

  __openMenuMobile = (e) => {
    const rewards = EnrollStorage.getRewards();
    const { licenseId } = EnrollStorage.getEnrollSelected();
    let path = location.pathname;

    this.isOpen = true;
    this.__body.classList.toggle('block-scroll', true);
    this.__rewards = rewards.general;
    this.activeLink(path.replace("/", ""));

    RewardsApi.read.getRewards({ eid: licenseId }, (resp) => {
      resp.eid = licenseId;
      EnrollStorage.setRewards(resp);
      this.__rewards.ludus = resp.lus_g;
      this.__rewards.ludis = resp.lns_g;
      this.__rewards.likes = resp.lks_g;
      this.__rewards.achievements = resp.ac_g;
      this.render();
    });
  }

  __scrollHandler(e) {
    const body = this.querySelector(".mobile-menu-body");
    const scrollY = body.scrollTop;
    const header = this.querySelector(".mobile-menu-header")

    if (header && scrollY > 20) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  }

  closeMenuModal = () => {
    this.isOpen = false;
    this.__body.classList.toggle('block-scroll', false);
  }

  navigatePage(path) {
    this.closeMenuModal();
    if(path == 'logout'){
      StorageService.clearAll();
      window.location.href = "/";
      return;
    }

    if (path == 'subscriptions') {
      window.open(E_COMMERCE + "suscripciones", "_blank");
      return;
    }

    if (path == 'support') {
      const destinatario = 'hola@meludus.com';
      const asunto = 'Asunto del correo';
      const mailtoLink = `mailto:${destinatario}?subject=${encodeURIComponent(asunto)}`;
      window.open(mailtoLink);
      return;
    }
    
    this.activeLink(path);
    navigateTo(`/${path}`);
  }

  activeLink(path){
    const testPages = ['mis-tests', 'crear-test', 'hacer-test', 'resumen-resultados-test'];
    const bookPages = ['libro', 'esquema'];
    
    if (testPages.includes(path)) path = 'mis-tests';
    if (bookPages.includes(path)) path = 'libro';

    let body = this;
    let cls = `.li-${path}`;
    body.querySelector('.option-wrap.active')?.classList.remove('active');
    body.querySelector(cls)?.classList?.add('active');
  }

  getNavItem(link, text, icon){
    return html`
      <div @click=${() => this.navigatePage(link)} class="option-wrap li-${link}">
        <div class="option">
          <i class="icon-1 ${icon}"></i>
          <span>${text}</span>
        </div>
      </div>
    `;
  }

  getNavItemV2(link, text, icon) {
    return html`
      <div @click=${() => this.navigatePage(link)} class="footer-btn">
        <i class="icon-1 ${icon}"></i>
        <span>${text}</span>
      </div>
    `;
  }

  reload(){
    this.render();
  }

  startVideoTutorials(){
    document.dispatchEvent(new CustomEvent('startTutorialVideos', {
      detail: { start: true }
    }))
  }

  template() {
    const { user, ua } = AuthStorage.getAuth();
    const { isOpen, __avatar: defaultAvatar, __rewards: rewards } = this;
    const avatar = `${ URL_STATIC }avatar/${ user.pid }-thumbnail.png?${ new Date().getTime() }`;

    return /* template */html`
      <div class="menu-popup ${ isOpen ? 'show' : ''}">
        <div class="square-top" style="aspect-ratio: 1 / 1"></div>

        <div class="mobile-menu-header">
          <img width="130" src="${ ua.ua_logo_url }" alt="logo"/>
          <i @click=${ this.closeMenuModal } class="icon-1 ml-icon-mal"></i>
        </div>

        <div class="mobile-menu-body">
          <div class="scrollable-content">
            <div class="avatar-wrap">
              <div class="img-wrap">
                <img
                  width="80"
                  src="${ avatar }"
                  onerror="this.src='${defaultAvatar + '?' + new Date().getTime()}'"
                  alt="avatar">
              </div>
              <p>${ user.nick }</p>
            </div>

            <div class="user-info">
              <div class="user-reward">
                <i class="icon-1 ml-icon-ludus"></i>
                <span>${ rewards?.ludus }</span>
              </div>

              <div class="user-reward">
                <i class="icon-1 ml-icon-ludin"></i>
                <span>${ rewards?.ludis }</span>
              </div>

              <div class="user-reward">
                <i class="icon-1 ml-icon-ranking_medalla"></i>
                <span>${ rewards?.achievements }</span>
              </div>

              <div class="user-reward">
                <i class="icon-1 ml-icon-like"></i>
                <span>${ rewards?.likes }</span>
              </div>
            </div>

            <div class="menu-options">
              ${ this.getNavItem('panel-de-usuario', 'Estadísticas', 'ml-icon-analiticas-1') }
              ${ this.getNavItem('mis-tests', 'Tests', 'ml-icon-test') }
              ${ this.getNavItem('rankings', 'Ranking', 'ml-icon-menu_ranking') }
              ${ this.getNavItem('logros', 'Logros', 'ml-icon-ranking_medalla') }
              ${ this.getNavItem('deconstructor', 'Deconstructor', 'ml-icon-deconstructor') }
              ${ this.getNavItem('pomodoro', 'Pomodoro', 'ml-icon-pomodoro')}
              ${ this.getNavItem('opozulo', 'Luduzulo', 'ml-icon-casa') }
            </div>
          </div>
        </div>

        <div class="mobile-menu-footer">
          <div class="square-bottom" style="aspect-ratio: 1 / 1"></div>

          <div class="footer-group">
            ${ this.getNavItemV2('subscriptions', 'suscripciones', 'ml-icon-calendario') }
            ${ this.getNavItemV2('support', 'soporte', 'ml-icon-email') }
            ${ this.getNavItemV2('logout', 'salir', 'ml-icon-salir') }
          </div>
        </div>
      </div>
    `;
  }
}


window.customElements.define('ml-menu-mobile', MobileMenu);

