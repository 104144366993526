import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { enroll } from './../../urls';
import { getGlobalDataForApi } from "./../utils";

export const getTree = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(enroll.getTree, data),
    (resp) => { success(resp.getTree) },
    (_error) => { if(error) error(_error) },
    { api: enroll.getTree, data}
  );
}

export const getCustomTree = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(enroll.getCustomTree, data),
    (resp) => { success(resp.getCustomTree) },
    (_error) => { if(error) error(_error) },
    { api: enroll.getCustomTree, data}
  );
}

export const getStudyGoal = async(data, success, error) => {
  getGlobalDataForApi(data);
  data.pid = data.person_id;
  HandleRequest(
    () => ApiService.post(enroll.getStudyGoal, data),
    (resp) => success(resp.getStudyGoal),
    (_error) => { if(error) error(_error) },
    { api: enroll.getStudyGoal, data}
  );
}

