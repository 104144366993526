import { render, html } from 'lit-html';
import { toCamelCase } from 'utils/to-camel-case';
import styles from './_.styles.scss';
import { treatLuduFace } from './utils';

class LuduModal extends HTMLElement {

  /** Definition of component props to be listened when they change */
  static get observedAttributes() {
    return [
      'heading-title',
      'open',
      'face',
      'text',
    ];
  }

  get headingTitle() {return this.getAttribute('heading-title') || undefined}
  set headingTitle(value) {this.setAttribute('heading-title', value)}

  get text() {return this.getAttribute('text') || undefined}
  set text(value) {this.setAttribute('text', value)}

  get open() {return this.getAttribute('open') === 'true'}
  set open(value) {
    const v = value === 'true';
    this.setAttribute('open', v)
  }

  get face() { return this.getAttribute('face'); }
  set face(value) {
    this.setAttribute('face', treatLuduFace(value));
  }


  /** Initializer of component definition */
  constructor() {
    super();
    this.__body = document.body;
    this.__assets = "https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/statics/guidelines/gif/coach";
    this.#__init();
  }

  /** Element created and initialized in DOM */
  connectedCallback() {
    this.__initialized = true;
    this.toggleBlockScrollIfNeeded();
    this.#__render();
  }

  /** Trigger change props provided to the component */
  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.__initialized) {
      if (oldValue !== newValue) {
        this[toCamelCase(attributeName)] = newValue
        this.toggleBlockScrollIfNeeded();
        this.#__render();
      }
    }
  }

  toggleBlockScrollIfNeeded() {
    if (this.open) {
      this.__body.classList.add('block-scroll');
      this.classList.add('visible');
      return;
    } 
    this.__body.classList.remove('block-scroll');
    if(this.classList.contains('visible')) this.classList.add('visible-out');
    setTimeout(() => {
      this.classList.remove('visible');
      this.classList.remove('visible-out');
    }, 500);
  }

  /** ______________________ RENDER ______________________________ */
  #template() {
    const { headingTitle, face, text } = this;

    return /* template */html`
      <div class="overlay"></div>
      <div class="ml_a-modal">
        <div class="ml_ludu">
          <div class="ml_ludu_msg">
            <p class="title_4 ml_primary mb-5">${headingTitle}</p>
            <div class="msg_content">
              <p class="p_14 regular">${text}</p>
            </div>
          </div>
          <img src="${this.__assets}/${treatLuduFace(face)}.gif" alt="ludubot">
        </div>
        <div class="h-full flex justify-evenly flex-wrap gap-y-5">
          <slot></slot>
        </div>
      </div>
    `;
  }
  /** ____________________________________________________________ */



  /**
   * ========================= PRIVATE FUNCTIONS ===========================
   * (Don't modify the functions below. Copy-paste to other web components)
   * ==================================================================== */

  /** Init web component config to be executed in constructor */
  #__init({ shadowDOM = true } = {}) {
    this.__isShadowDOM = shadowDOM;
    if (this.__isShadowDOM) this.root = this.attachShadow({ mode: 'open'});
    this.#__render();
    this.#__attachStyle();
    this.__initialized = false;
  }

  /** Inject processed SCSS Style */
  #__attachStyle() {
    const style = document.createElement('style');
    style.textContent = styles;
    const appendStyleTo = this.__isShadowDOM
      ? this.root
      : document.head || document.getElementsByTagName('head')[0];

    appendStyleTo.appendChild(style);
  }


  /** Internal function to update the UI based (re-render and first render) */
  #__render() {
    const attachTo = this.__isShadowDOM ? this.root : this;
    render(this.#template(), attachTo);
  }
  /** ============================================================= */
}

window.customElements.define('ml-ludu-modal', LuduModal);

